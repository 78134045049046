import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['textRevealScreen1', 'textRevealScreen2', 'textRevealScreen3'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const SingleImage = makeShortcode("SingleImage");
const TextReveal = makeShortcode("TextReveal");
const GoTo = makeShortcode("GoTo");
const Img = makeShortcode("Img");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "encouraging-healthy-screen-use-habits"
    }}>{`Encouraging healthy screen use habits`}</h1>
    <p>{`You can help your teenager develop good habits around screen use by establishing family rules about screen time, and modelling healthy screen use yourself. This may be even more important during the pandemic.`}</p>
    <Grid container spacing={3} mdxType="Grid">
  <Grid item xs={12} sm={6} mdxType="Grid">
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m7/14.svg" alt="rules" mdxType="SingleImage" />
    <TextReveal id="textRevealScreen1" header={<h4>Family rules about screen time (click to reveal)</h4>} mdxType="TextReveal">
      <div>
        <p>
          Having some clear rules or guidelines about screen time can help you and your teen to keep your screen use
          healthy. Think about:
        </p>
        <ul>
          <li>
            <i>Where</i> screen use is allowed (e.g. in common areas vs. bedrooms, at the dinner table)
          </li>
          <li>
            <i>When</i> screen use is allowed (e.g. after homework or chores, not in the hour before bed)
          </li>
          <li>
            <i>What</i> your teen is allowed to do online (e.g. are certain websites/activities not allowed?)
          </li>
          <li>
            <i>How long</i> your teen can use devices for, without a break (aim for short sessions with regular breaks)
          </li>
        </ul>
        <p>
          Try to involve your teenager in setting the rules and talk with them about the reasons behind the rules. If
          you are making changes, start with small changes first (e.g. cut down screen time gradually). Getting the
          whole family on board with the rules about screen use will help make these changes seem more acceptable and
          fair.
        </p>
        <p>
          For more information about setting family rules, check out the module{' '}
          <GoTo to="/dashboard" mdxType="GoTo">
            <i>Raising good kids into great adults: Establishing family rules</i>
          </GoTo>
          .<br /> <br />
          <Img style={{
                width: '64px',
                height: '64px'
              }} src="/images/m2/1-symbol.svg" alt="Pandemic Icon" mdxType="Img" />
          During the pandemic, you’ll need to adjust the rules to support your teen’s education and social connections. You
          may need to reconsider the rules as local restrictions change.
        </p>
      </div>
    </TextReveal>
  </Grid>
  <Grid item xs={12} sm={6} mdxType="Grid">
    <SingleImage smallGridSize={7} gridSize={5} src="/images/m7/11-01.svg" alt="technology" mdxType="SingleImage" />
    <TextReveal id="textRevealScreen2" header={<h4>Consider your own screen use (click to reveal)</h4>} mdxType="TextReveal">
      <div>
        <p>
          How you use technology influences how your teen uses technology. Try to set a good example by role-modelling
          healthy screen use yourself. Your teenager will be more likely to follow rules about screen time if you do
          this.
        </p>
        <p>Some questions to ask yourself include:</p>
        <ul>
          <li>
            <i>Do you have screen-free time each day? </i>
            If you want your teenager to spend more time offline, show them that it is possible by doing this yourself!
          </li>
          <li>
            <i>Do you wind-down or relax without screens? </i>
            For example, going for a walk or playing a screen-free game – show your teen that there are relaxing, fun things
            that they can do without technology.
          </li>
          <li>
            <i>Do you carry your phone with you wherever you go? </i>
            This might teach your teen that it’s important to always have their phone on them.
          </li>
          <li>
            <i>Do you have your phone/devices with you during family meals? </i>
            You could try keeping family meal times free from technology.
          </li>
          <li>
            <i>Do you spend screen-free time with your teenager? </i>
          </li>
        </ul>
        <p>Show your teen that they matter more to you than your ‘screen’!</p>
      </div>
    </TextReveal>
  </Grid>
    </Grid>
    <br />
    <TextReveal id="textRevealScreen3" header={<h4>Is your teen going online to escape from their problems? (click here for a tip)</h4>} mdxType="TextReveal">
  <p>
    If you think your teen is going online to escape from or cope with challenges they are facing, they may need extra
    support to cut down on screen time. Making sudden changes to rules might make it hard for your teen to cope, so
    start with small changes. Before you try to limit their screen time, try to first understand what’s going on for
    them. Check out the{' '}
    <GoTo to="/dashboard" mdxType="GoTo">
      <i>Connect</i>
    </GoTo>{' '}
    module for ideas on how to do this.
  </p>
    </TextReveal>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      